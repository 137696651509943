import React, { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useStripeProducts from "../products/useStripeProducts";

import { formatCurrencyString, useShoppingCart } from "use-shopping-cart";
import { Card, CircularProgress } from "@mui/material";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { Button } from "gatsby-theme-material-ui";
import Backdrop from "@mui/material/Backdrop";
import { viewCartEventGA } from "../../../utils/ga-events";
import { StaticImage } from "gatsby-plugin-image";

const imgStyle = {
  borderRadius: "10px",
  width: "100%",
  maxHeight: "250px",
  minHeight: "250px",
  objectFit: "cover",
};
const SoftwareUpsellSection = ({ allStripeProducts, ...props }) => {
  const [open, setOpen] = useState(false);
  const { addItem } = useShoppingCart();
  const products = useStripeProducts();

  const { handleCartClick, cartDetails, totalPrice } = useShoppingCart();

  const handleClick = () => {
    handleCartClick();
    viewCartEventGA(cartDetails, totalPrice);
  };

  const shutterDisc = products.find(
    (product) =>
      product.displayName === "Bluetooth Shutter" &&
      product.ecommerceDiscount &&
      product.ecommercePrice
  );

  const shutter = products.find(
    (product) =>
      product.displayName === "Bluetooth Shutter" &&
      product.ecommerceDiscount == null
  );

  const quikvueStripe = products.filter((product) => {
    return product && product.name && product.name.includes("QVP");
  });

  const nepStripe = products.filter((product) => {
    return product && product.name && product.name.includes("NEP");
  });

  const handleClose = () => {
    setOpen(false);
  };

  const addAccessory = (accessoryItem) => {
    // setOpen(true);
    handleClick();
    addItem(accessoryItem);
    // setTimeout(() => {
    //   setOpen(false);
    // }, 700);
  };

  return (
    <Box sx={{ padding: 1, marginTop: 1 }}>
      <Container maxWidth={"lg"}>
        <Grid container style={{ justifyContent: "space-between" }}>
          <Grid
            xs={11.5}
            sm={5.8}
            md={3.7}
            className="margin_top_1"
            style={{ display: "flex" }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflow: "visible",
                borderRadius: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: "white",
                  backgroundColor: "#FFA100",
                  padding: "7px 20px",
                  borderRadius: "30px",
                  position: "absolute",
                  top: "0",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  fontWeight: "bold",
                  width: "70%",
                  zIndex: "1",
                }}
              >
                🔥 OPPORTUNITY
              </div>
              <div>
                <StaticImage
                  style={imgStyle}
                  src="./1 Button.png"
                  loading="lazy"
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                ></StaticImage>
                <div style={{ padding: "20px", paddingTop: "0" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                  >
                    {shutter.displayName}
                  </div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        textDecoration: "line-through",
                        fontWeight: "bold",
                      }}
                    >
                      {formatCurrencyString({
                        value: shutter.price,
                        currency: shutter.currency,
                      })}
                    </div>
                    <div style={{ color: "#2EC4B6", marginLeft: "1em" }}>
                      {formatCurrencyString({
                        value: shutterDisc.price,
                        currency: shutterDisc.currency,
                      })}
                    </div>
                  </div>
                  <div>
                    Remote Shutter Command. No need to touch the phone to take
                    pictures or videos.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "auto",
                    padding: "0px 20px",
                    paddingTop: "1em",
                  }}
                >
                  <a
                    href="/store/bluetooth-shutter"
                    target="_blank"
                    style={{
                      color: "#838383",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Learn more
                  </a>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => addAccessory(shutterDisc)}
                    color="primary"
                    variant="contained"
                    endIcon={<AddShoppingCartOutlinedIcon />}
                    sx={{
                      padding: "8px 30px",
                      marginTop: "1em",
                      borderRadius: "10px",
                      color: "white",
                      marginBottom: "1em",
                    }}
                  >
                    Add to Cart
                  </Button>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                    onClick={handleClose}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid
            xs={11.5}
            sm={5.8}
            md={3.7}
            className="margin_top_1"
            style={{ display: "flex" }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflow: "visible",
                borderRadius: "10px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <StaticImage
                  style={imgStyle}
                  src="./1 TN.png"
                  loading="lazy"
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                ></StaticImage>
                <div style={{ padding: "20px", paddingTop: "0" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                  >
                    {nepStripe[0].displayName}
                  </div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>
                      {formatCurrencyString({
                        value: nepStripe[0].price,
                        currency: nepStripe[0].currency,
                      })}
                    </div>
                  </div>
                  <div>
                    Elbow support designed to use with slit lamps or ALK lasers.
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "auto",
                    padding: "0px 20px",
                    paddingTop: "1em",
                  }}
                >
                  <a
                    href="/store/takeanep"
                    target="_blank"
                    style={{
                      color: "#838383",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Learn more
                  </a>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => addAccessory(nepStripe[0])}
                    color="primary"
                    variant="contained"
                    endIcon={<AddShoppingCartOutlinedIcon />}
                    sx={{
                      padding: "8px 30px",
                      marginTop: "1em",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    Add to Cart
                  </Button>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid
            xs={11.5}
            sm={5.8}
            md={3.7}
            className="margin_top_1"
            style={{ display: "flex" }}
          >
            <Card
              style={{
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <StaticImage
                  style={imgStyle}
                  src="./Quikvue7 1.png"
                  loading="lazy"
                  placeholder="blurred"
                  formats={["auto", "webp", "avif"]}
                ></StaticImage>
                <div style={{ padding: "20px", paddingTop: "0" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                  >
                    {quikvueStripe[0].displayName}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>
                      {formatCurrencyString({
                        value: quikvueStripe[0].price,
                        currency: quikvueStripe[0].currency,
                      })}
                    </div>
                  </div>
                  <div>
                    Image capturing for primary eye care examination and
                    telemedicine
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "auto",
                    padding: "0px 20px",
                    paddingTop: "1em",
                  }}
                >
                  <a
                    href="/store/quikvue"
                    target="_blank"
                    style={{
                      color: "#838383",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Learn more
                  </a>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => addAccessory(quikvueStripe[0])}
                    color="primary"
                    variant="contained"
                    endIcon={<AddShoppingCartOutlinedIcon />}
                    sx={{
                      padding: "8px 30px",
                      marginTop: "1em",
                      borderRadius: "10px",
                      color: "white",
                      marginBottom: "1em",
                    }}
                  >
                    Add to Cart
                  </Button>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SoftwareUpsellSection;
